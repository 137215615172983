import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, unref as _unref } from "vue";
const _hoisted_1 = {
  class: "contract-order"
};
const _hoisted_2 = {
  class: "tables"
};
const _hoisted_3 = {
  key: 0,
  class: "constTables"
};
const _hoisted_4 = {
  class: "th acea-row row-between-wrapper"
};
const _hoisted_5 = {
  class: ""
};
const _hoisted_6 = {
  class: "text_rit"
};
const _hoisted_7 = {
  class: "cexiao"
};
const _hoisted_8 = {
  class: "pagination"
};
const _hoisted_9 = {
  key: 1,
  class: "no_more"
};
const _hoisted_10 = {
  key: 0,
  class: "constTables"
};
const _hoisted_11 = {
  class: "th acea-row row-between-wrapper"
};
const _hoisted_12 = {
  class: ""
};
const _hoisted_13 = {
  class: "text_rit"
};
const _hoisted_14 = {
  class: "pagination"
};
const _hoisted_15 = {
  key: 1,
  class: "no_more"
};
const _hoisted_16 = {
  class: "cancel-popup"
};
const _hoisted_17 = {
  class: "cancel-popup__title"
};
const _hoisted_18 = {
  class: "cancel-popup__foot"
};
import NP from 'number-precision';
import { router } from '@/router';
import { ref, watch } from 'vue';
import { liquidityOrder, cancelLpOrder } from '@/http';
import { showToast } from 'vant';
export default {
  __name: 'liquidityOrder',
  setup(__props) {
    const list = ref([]);
    const total = ref(0);
    const pageSize = ref(5);
    const page = ref([1, 1]);
    const active = ref(0);
    const loading = ref([false, false]);
    const finished = ref([false, false]);
    const show = ref(false);
    const selectItem = ref(null);
    const _cancelLpOrder = item => {
      selectItem.value = item;
      show.value = true;
    };
    const _cancel = () => {
      cancelLpOrder({
        lp_order_id: selectItem.value.id
      }).then(({
        msg
      }) => {
        show.value = false;
        showToast(msg);
        _liquidityOrder();
      });
    };
    const _liquidityOrder = () => {
      liquidityOrder({
        type: active.value,
        page: page.value[active.value],
        perPage: pageSize.value
      }).then(({
        data
      }) => {
        list.value = data.data;
        total.value = data.total;
      });
    };
    _liquidityOrder();
    const changeList = net => {
      console.log(net);
      page.value[active.value] = net;
      _liquidityOrder();
    };
    watch(active, newVal => {
      _liquidityOrder();
    });
    const back = () => {
      router.go(-1);
    };
    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_pagination = _resolveComponent("el-pagination");
      const _component_el_tab_pane = _resolveComponent("el-tab-pane");
      const _component_el_tabs = _resolveComponent("el-tabs");
      const _component_van_button = _resolveComponent("van-button");
      const _component_van_popup = _resolveComponent("van-popup");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_tabs, {
        modelValue: active.value,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => active.value = $event),
        class: "demo-tabs"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_tab_pane, {
          label: _ctx.$t('in_progress'),
          name: 0
        }, {
          default: _withCtx(() => [list.value.length ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", null, _toDisplayString(_ctx.$t('information')), 1), _createElementVNode("div", null, _toDisplayString(_ctx.$t('amount')), 1), _createElementVNode("div", null, _toDisplayString(_ctx.$t('daily_profit')), 1), _createElementVNode("div", null, _toDisplayString(_ctx.$t('total_return_rate')), 1), _createElementVNode("div", null, _toDisplayString(_ctx.$t('due_date')), 1), _createElementVNode("div", null, _toDisplayString(_ctx.$t('return_rate')), 1), _createElementVNode("div", null, _toDisplayString(_ctx.$t('order_id')), 1), _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('creation_time')), 1), _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('operate')), 1)]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(list.value, (item, index) => {
            return _openBlock(), _createElementBlock("div", {
              class: "tds acea-row row-between-wrapper",
              key: index
            }, [_createElementVNode("div", null, [_createElementVNode("div", null, _toDisplayString(item.days) + _toDisplayString(_ctx.$t('day')), 1)]), _createElementVNode("div", null, [_createElementVNode("p", null, _toDisplayString(parseFloat(item.amount)) + " (USD)", 1)]), _createElementVNode("div", null, [_createElementVNode("p", null, _toDisplayString(parseFloat(item.one_rate)) + " (USD)", 1)]), _createElementVNode("div", null, [_createElementVNode("p", null, _toDisplayString(parseFloat(item.tot_rate) || '-'), 1)]), _createElementVNode("div", null, _toDisplayString(item.days), 1), _createElementVNode("div", null, [_createElementVNode("span", null, _toDisplayString(item.rate) + "%", 1)]), _createElementVNode("div", null, [_createElementVNode("span", null, _toDisplayString(item.id), 1)]), _createElementVNode("div", null, [_createElementVNode("span", null, _toDisplayString(item.created_at), 1)]), _createElementVNode("div", _hoisted_7, [_createVNode(_component_el_button, {
              onClick: $event => _cancelLpOrder(item),
              type: "success",
              link: ""
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('cancel_order')), 1)]),
              _: 2
            }, 1032, ["onClick"])])]);
          }), 128)), _createElementVNode("div", _hoisted_8, [_createVNode(_component_el_pagination, {
            size: "small",
            background: "",
            layout: "prev, pager, next",
            total: total.value,
            class: "mt-4",
            "page-size": pageSize.value,
            "current-page": page.value[0],
            "onUpdate:currentPage": _cache[0] || (_cache[0] = $event => page.value[0] = $event),
            onChange: changeList
          }, null, 8, ["total", "page-size", "current-page"])])])) : (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.$t('no_more')), 1))]),
          _: 1
        }, 8, ["label"]), _createVNode(_component_el_tab_pane, {
          label: _ctx.$t('has_ended'),
          name: 1
        }, {
          default: _withCtx(() => [list.value.length ? (_openBlock(), _createElementBlock("div", _hoisted_10, [_createElementVNode("div", _hoisted_11, [_createElementVNode("div", null, _toDisplayString(_ctx.$t('information')), 1), _createElementVNode("div", null, _toDisplayString(_ctx.$t('amount')), 1), _createElementVNode("div", null, _toDisplayString(_ctx.$t('daily_profit')), 1), _createElementVNode("div", null, _toDisplayString(_ctx.$t('total_return_rate')), 1), _createElementVNode("div", null, _toDisplayString(_ctx.$t('due_date')), 1), _createElementVNode("div", null, _toDisplayString(_ctx.$t('return_rate')), 1), _createElementVNode("div", null, _toDisplayString(_ctx.$t('order_id')), 1), _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t('creation_time')), 1), _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t('expiration_time')), 1)]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(list.value, (item, index) => {
            return _openBlock(), _createElementBlock("div", {
              class: "tds acea-row row-between-wrapper",
              key: index
            }, [_createElementVNode("div", null, [_createElementVNode("div", null, _toDisplayString(item.days) + _toDisplayString(_ctx.$t('day')), 1)]), _createElementVNode("div", null, [_createElementVNode("p", null, _toDisplayString(parseFloat(item.amount)) + " (USD)", 1)]), _createElementVNode("div", null, [_createElementVNode("p", null, _toDisplayString(parseFloat(item.one_rate)) + " (USD)", 1)]), _createElementVNode("div", null, [_createElementVNode("p", null, _toDisplayString(parseFloat(item.tot_rate) || '-'), 1)]), _createElementVNode("div", null, _toDisplayString(item.days), 1), _createElementVNode("div", null, [_createElementVNode("span", null, _toDisplayString(item.rate) + "%", 1)]), _createElementVNode("div", null, [_createElementVNode("span", null, _toDisplayString(item.id), 1)]), _createElementVNode("div", null, [_createElementVNode("span", null, _toDisplayString(item.created_at), 1)]), _createElementVNode("div", null, [_createElementVNode("span", null, _toDisplayString(item.end_time), 1)])]);
          }), 128)), _createElementVNode("div", _hoisted_14, [_createVNode(_component_el_pagination, {
            size: "small",
            background: "",
            layout: "prev, pager, next",
            total: total.value,
            class: "mt-4",
            "page-size": pageSize.value,
            "current-page": page.value[0],
            "onUpdate:currentPage": _cache[1] || (_cache[1] = $event => page.value[0] = $event),
            onChange: changeList
          }, null, 8, ["total", "page-size", "current-page"])])])) : (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString(_ctx.$t('no_more')), 1))]),
          _: 1
        }, 8, ["label"])]),
        _: 1
      }, 8, ["modelValue"])])]), _createVNode(_component_van_popup, {
        show: show.value,
        "onUpdate:show": _cache[4] || (_cache[4] = $event => show.value = $event),
        round: "",
        closeable: ""
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_16, [_createElementVNode("p", _hoisted_17, _toDisplayString(_ctx.$t('confirm_cancellation')) + "?", 1), _createElementVNode("p", null, _toDisplayString(_ctx.$t('penalty_rate')) + _toDisplayString(selectItem.value.dedit) + "%，" + _toDisplayString(_ctx.$t('expected_arrival_amount')) + "：" + _toDisplayString(_unref(NP).minus(selectItem.value.amount, _unref(NP).times(selectItem.value.amount, selectItem.value.dedit / 100))) + "USD", 1), _createElementVNode("div", _hoisted_18, [_createVNode(_component_van_button, {
          round: "",
          onClick: _cache[3] || (_cache[3] = $event => show.value = false)
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('cancel')), 1)]),
          _: 1
        }), _createVNode(_component_van_button, {
          round: "",
          color: "#56F9AA",
          onClick: _cancel
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('confirm')), 1)]),
          _: 1
        })])])]),
        _: 1
      }, 8, ["show"])], 64);
    };
  }
};